'use client';

/* eslint-disable @next/next/no-img-element
-- This component intentionally opts out of the Next.js `Image` behavior. */

import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import MediaService, {
  IMediaOptimizationOptions
} from '@/services/isomorphic/MediaService';

import { IImage, ImageModel } from '@/services/models/Media/Image';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { CSSObjectFit } from '@/type-utils/css';

import { InvalidArgumentError } from '@/utils/errors';

import { useIsomorphicLayoutEffect } from '@/react/hooks/useIsomorphicLayoutEffect';
import { useZoomLevel } from '@/react/hooks/useZoomLevel';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { DTO, Nullable } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import IStylable from '../../traits/IStylable';
import SkeletonLoader from '../../utility/SkeletonLoader';
import S from './styles.module.scss';

export interface IImageProps extends IStylable {
  /** Image model or DTO to display. */
  image: IImage;

  /**
   * Represents the _rendered_ width in pixels, so it will affect how large the image appears.
   */
  width?: number;

  /**
   * Represents the _rendered_ height in pixels, so it will affect how large the image appears.
   */
  height?: number;

  /**
   * Causes the image to fill the parent element instead of setting `width` and `height`.
   */
  fill?: boolean;

  /**
   * Defines how the image should be resized to fit its container.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
   */
  fit?: CSSObjectFit;

  /**
   * Specifies whether to load a higher resolution version of the provided image when
   * native browser zoom has been detected. Only has an effect when `optimization` is
   * set to `"auto"`.
   *
   * Note: This prop must be constant, i.e. it should not change during the lifetime
   * of the component.
   */
  enhanceOnZoom?: boolean;

  /**
   * When `true`, the image will be considered high priority and preload.
   * Lazy loading is automatically disabled for images using priority.
   *
   * @see [next/image.preload](https://nextjs.org/docs/api-reference/next/image#priority).
   */
  priority?: boolean;

  /** Optimization options.
   *
   * - `"auto"`: Default value. Will attempt to automatically optimize the image.
   * - `"none"`: All optimizations are disabled and the image is displayed as-is.
   * - {@link IMediaOptimizationOptions} object: Will use custom optimization options as specified.
   */
  optimization?: 'auto' | 'none' | IMediaOptimizationOptions;

  /**
   * If a Skeleton Loader should show as a placeholder before the image loads.
   *
   * If the provided image also has a {@link IImage.blurURL `blurURL`}, the Skeleton will only display
   * until the blur image loads.
   *
   * Defaults to `true`, but it is recommended to set it as `false` when
   * displaying images with transparent backgrounds to prevent the visible
   * unmounting of the skeleton on load.
   */
  useSkeletonLoader?: boolean;
}

/**
 * First-party image component built on top of the Next Image component.
 * Adds additional functionalities such as optimization support.
 *
 * @throws An {@link InvalidArgumentError} if `width` and/or `height` is missing and `fill` is not set to true.
 */
export const Image: FunctionComponent<IImageProps> = ({
  image,
  width,
  height,
  fill = true,
  fit = 'contain',
  enhanceOnZoom: enhanceOnZoomProp = false,
  priority = false,
  optimization = 'auto',
  useSkeletonLoader = true,
  className,
  style
}) => {
  // `enhanceOnZoom` should not change during the lifetime of the component. To enforce this,
  // we use `useState` to create a constant state that will never change.
  const [enhanceOnZoom] = useState<boolean>(enhanceOnZoomProp);

  // This state will hold the image dimensions; either calculated on mount or manually specified.
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(
    width !== undefined && height !== undefined ? { width, height } : null
  );

  // This state will hold the aspect ratio of the image. If the image is a model, it will
  // grab the stale value from the model. If it's a DTO, it will grab the value from the
  // DTO - whether that's a number, null, or undefined.
  const [aspectRatio] = useState<Nullable<number>>(
    image.aspectRatio instanceof StaleWhileRevalidate
      ? image.aspectRatio.value
      : (image as DTO<IImage>).aspectRatio
  );

  // If `enhanceOnZoom` is enabled, use the zoom level hook. Otherwise use a default zoom level of 1.
  /* eslint-disable-next-line react-hooks/rules-of-hooks -- This conditional hook call is allowed because
  we enforce that `enhanceOnZoom` is constant and will never change during the lifetime of the component. */
  const isZoomedIn = enhanceOnZoom ? useZoomLevel().isZoomedIn : false;

  // The working image model.
  const [imageModel, setImageModel] = useState<ImageModel | null>(null);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [blurLoaded, setBlurLoaded] = useState<boolean>(false);

  // Whenever dimensions or the image change...
  useEffect(() => {
    (async () => {
      const optimize = optimization !== 'none';
      // Make a model from the supplied image.
      const newImageModel = ImageModel.from(image);
      // If optimization is enabled...
      if (optimize) {
        // ...automatic optimization is enabled...
        if (optimization === 'auto') {
          // ...and the container dimensions have been calculated...
          if (dimensions) {
            // Optimize the image considering the width it is being displayed with.
            newImageModel.optimize({
              // If zoomed in, increase the resolution of the image to the nearest known
              // image size by a factor of 2.
              width:
                enhanceOnZoom && isZoomedIn
                  ? dimensions.width * 2
                  : dimensions.width
            });
            setImageModel(newImageModel);
          }
          // If automatic optimization is enabled and dimensions are not yet available, do nothing.
          // The effect will be called again once dimensions are calculated.
        } else {
          // If you are here, `optimization` is a custom optimization options object.
          // Just optimize the model with it and call it a day.
          newImageModel.optimize(optimization);
          setImageModel(newImageModel);
        }
      } else {
        // If optimization is disabled, this will just be the supplied image made into a model.
        setImageModel(newImageModel);
      }
    })();
  }, [dimensions, image, optimization, isZoomedIn, enhanceOnZoom]);

  // This ref will be assigned to the parent image container.
  const containerRef = useRef<HTMLDivElement>(null);

  // Using `useLayoutEffect` here because we want the dimensions calculated only after
  // the DOM has finished rendering so they are accurate.
  // Good resource on this: https://kentcdodds.com/blog/useeffect-vs-uselayouteffect#uselayouteffect
  useIsomorphicLayoutEffect(() => {
    if ((typeof window !== "undefined")) {
      // Only calculate dimensions if not pre-specified.
      if (!dimensions && containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = containerRef.current.offsetHeight;

        setDimensions({ width, height });
      }
    }
  }, [image, dimensions]);

  // Validate dimensions
  if (width === undefined && height === undefined) {
    // If there's no dimensions specified, verify if `fill` is true.
    if (!fill) {
      throw new InvalidArgumentError(
        '`Image` requires either both `width` and `height` to be present or `fill` to be true.'
      );
    }
  }

  const content = useMemo(() => {
    if (dimensions) {
      const imageDimensions = !fill
        ? { width: dimensions.width, height: dimensions.height }
        : {};

      if (imageModel) {
        /**
         * If one of these becomes observable, then this destructuring should be
         * considered again.
         */
        const { src, alt, blurURL, caption } = imageModel;

        return (
          <>
            {blurURL !== null && blurURL !== '' && (
              <div
                className={classes(S.placeholderContainer, S.fadeIn, {
                  [S.visible]: !loaded && blurLoaded
                })}
              >
                <img
                  className={classes(S.blurPlaceholder, S.fill)}
                  src={blurURL}
                  alt={alt}
                  style={{ objectFit: fit, ...imageDimensions }}
                  loading={priority ? 'eager' : 'lazy'}
                  onLoad={() => setBlurLoaded(true)}
                  title={caption ?? ''}
                />
              </div>
            )}

            <img
              src={src}
              alt={alt}
              className={classes(S.fadeIn, {
                [S.fill]: fill,
                [S.visible]: loaded
              })}
              title={caption ?? ''}
              style={{ objectFit: fit, ...imageDimensions }}
              loading={priority ? 'eager' : 'lazy'}
              onLoad={() => setLoaded(true)}
              onError={() => {
                // First, report this error.
                MediaService.reportMediaError(imageModel);

                setImageModel(
                  ImageModel.from({
                    uuid: '',
                    src: ConfigurationService.getConfig('media').getSetting(
                      'placeholderImageURL'
                    ).value,
                    alt: 'Image Unavailable',
                    width: 500,
                    height: 500
                  })
                );
              }}
            />
          </>
        );
      }
    }

    return null;
  }, [dimensions, imageModel, loaded, blurLoaded, fill, fit, priority]);

  return (
    <div
      ref={containerRef}
      style={{
        ...style,
        width,
        height,
        // If an aspect ratio is available, add it to the style.
        ...(aspectRatio !== null && aspectRatio !== undefined && aspectRatio > 0
          ? { aspectRatio: aspectRatio.toFixed(3) }
          : {})
      }}
      className={classes(S.image, className, fill && S.fill)}
    >
      {useSkeletonLoader && (
        <SkeletonLoader
          className={classes(
            S.skeletonLoader,
            (blurLoaded || loaded) && S.hidden
          )}
        />
      )}
      {content}
    </div>
  );
};
